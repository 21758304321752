import merge from 'lodash-es/merge'
import { defineStore } from 'pinia'
export const mainStore = defineStore('mainStore', {
  persist: { storage: sessionStorage },
  state: () => {
    return {
      currentPatientId: null,
      registerVisible: false,
      doctor_relationship: {
        doctor: {
          subject: {},
          title: {}
        },
        hospital_id: null,
        hospital: {},
        role: {
          perm: []
        },
        role_id: null
      },
      user_id: 0
    }
  },
  getters: {},
  actions: {
    setCurrentId (id) {
      this.currentPatientId = id
    },
    reset () {
      this.$reset()
    },
    logout () {
      this.$reset()
      sessionStorage.clear()
    },
    async setDoctor (doctor) {
      merge(this.doctor_relationship, doctor)
    },
    openDialog (name) {
      this[name] = true
    },
    closeDialog (name) {
      this[name] = false
    },
    setUserId (id) {
      this.user_id = id
    }
  }
})
