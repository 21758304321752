import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
import { routes } from '@/router';
import { mainStore } from '@/store';
import { computed, ref } from 'vue';
export default {
  __name: 'Nav',
  setup(__props) {
    const store = mainStore();
    const activeIndex = ref(sessionStorage.getItem('activeIndex') || 'patientlist');
    function selectMenu(menu) {
      sessionStorage.setItem('activeIndex', menu);
    }
    const filteredRoutes = computed(() => {
      return routes.filter(route => !route.meta?.hidden)[0].children;
    });
    const isShow = computed(() => {
      return code => {
        return code.some(item => store.doctor_relationship.role.perm.includes(item));
      };
    });
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_el_sub_menu = _resolveComponent("el-sub-menu");
      const _component_el_menu = _resolveComponent("el-menu");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createBlock(_component_el_scrollbar, {
        style: {
          "background-color": "#f3f3f3"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_menu, {
          "default-active": activeIndex.value,
          "text-color": "#000",
          "active-text-color": "#2076f7",
          "background-color": "#f3f3f3",
          "unique-opened": "",
          router: "",
          onSelect: selectMenu
        }, {
          default: _withCtx(() => [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredRoutes.value, (item, i) => {
            return _openBlock(), _createElementBlock("div", {
              key: i
            }, [!item.children ? _withDirectives((_openBlock(), _createBlock(_component_el_menu_item, {
              key: 0,
              index: item.path
            }, {
              default: _withCtx(() => [_createVNode(_component_el_icon, null, {
                default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(item.meta.icon)))]),
                _: 2
              }, 1024), _createTextVNode(" " + _toDisplayString(item.name), 1)]),
              _: 2
            }, 1032, ["index"])), [[_vShow, isShow.value(item.meta.code)]]) : _withDirectives((_openBlock(), _createBlock(_component_el_sub_menu, {
              key: 1,
              index: item.name
            }, {
              title: _withCtx(() => [_createVNode(_component_el_icon, null, {
                default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(item.meta.icon)))]),
                _: 2
              }, 1024), _createTextVNode(" " + _toDisplayString(item.name), 1)]),
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (items, i) => {
                return _withDirectives((_openBlock(), _createBlock(_component_el_menu_item, {
                  index: items.path,
                  key: i,
                  onClick: () => {
                    items.meta.click && items.meta.click();
                  }
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(items.name), 1)]),
                  _: 2
                }, 1032, ["index", "onClick"])), [[_vShow, isShow.value(items.meta.code)]]);
              }), 128))]),
              _: 2
            }, 1032, ["index"])), [[_vShow, isShow.value(item.meta.code)]])]);
          }), 128))])]),
          _: 1
        }, 8, ["default-active"])]),
        _: 1
      });
    };
  }
};