import "core-js/modules/es.array.push.js";
import { mainStore } from '@/store';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
const config = {
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000
};
const http = axios.create(config);
http.interceptors.request.use(config => {
  const store = mainStore();
  const token = sessionStorage.getItem('token');
  config.headers.hospital_id = store.doctor_relationship.hospital_id || 0;
  if (token) {
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    config.headers.authorization = `${token}`; // 请求头加上token
  }
  return config;
}, err => {
  Promise.reject(err);
});
http.interceptors.response.use(response => {
  const {
    data: resp
  } = response;
  const {
    data,
    code,
    message
  } = resp;
  if (typeof resp === 'string') {
    return resp;
  }
  if (code !== 200) {
    ElMessage.error(message);
    return Promise.reject(message);
  }
  if (resp && resp.data) {
    if (data.list === null) {
      data.list = [];
    }
  }
  return response.data;
}, err => {
  const store = mainStore();
  const router = useRouter();
  const {
    status
  } = err.response;
  ElMessage.error(responseCode[status]);
  if (status === 401) {
    store.reset();
    router.push('/');
  }
  return Promise.reject(err);
});
const responseCode = {
  400: '系统错误',
  401: '登录信息已失效，请重新登录。',
  403: '系统错误',
  404: '系统错误',
  405: '系统错误',
  406: '系统错误',
  410: '系统错误',
  422: '系统错误',
  500: '服务器出错，请联系维护人员',
  502: '服务器出错，请联系维护人员',
  503: '服务器出错，请联系维护人员',
  504: '服务器响应超时'
};
export default http;