import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f6d57ede"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "item"
};
const _hoisted_2 = {
  class: "left"
};
const _hoisted_3 = {
  class: "right"
};
export default {
  __name: 'Entry',
  props: ['title', 'main'],
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(__props.title), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(__props.main), 1)]);
    };
  }
};