import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-34133478"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
import Footer from '@/components/Footer/Footer.vue';
import Title from '@/components/Header/Title.vue';
import Nav from '@/components/nav/Nav';
export default {
  __name: 'Main',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_el_header = _resolveComponent("el-header");
      const _component_el_aside = _resolveComponent("el-aside");
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_card = _resolveComponent("el-card");
      const _component_el_main = _resolveComponent("el-main");
      const _component_el_container = _resolveComponent("el-container");
      const _component_el_footer = _resolveComponent("el-footer");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, null, {
        default: _withCtx(() => [_createVNode(_component_el_header, {
          height: "35px"
        }, {
          default: _withCtx(() => [_createVNode(Title)]),
          _: 1
        }), _createVNode(_component_el_container, {
          class: "main-box"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_aside, {
            width: "260px"
          }, {
            default: _withCtx(() => [_createVNode(_unref(Nav))]),
            _: 1
          }), _createVNode(_component_el_main, null, {
            default: _withCtx(() => [_createVNode(_component_el_card, null, {
              default: _withCtx(() => [_createVNode(_component_router_view, null, {
                default: _withCtx(({
                  Component,
                  route
                }) => [(_openBlock(), _createBlock(_KeepAlive, null, [route.meta.iskeepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: route.path
                })) : _createCommentVNode("", true)], 1024)), !route.meta.iskeepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: route.path
                })) : _createCommentVNode("", true)]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_footer, {
          height: "35px"
        }, {
          default: _withCtx(() => [_createVNode(Footer)]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }
};