import http from './http'

export function getUsers (options) {
  return http.get('/user', {
    params: options
  })
}

export function getUserBinds (options) {
  return http.get('/user_bind', {
    params: {
      ...options,
      expand_doctor: 1,
      expand_user: 1,
      expand_patient: 1,
      expand_hospital: 1
    }
  })
}

export function removeUserBind (id) {
  return http.delete(`/user_bind/${id}`)
}

export function getUserBindByUserId (options) {
  return http.get('/user_bind', {
    params: options
  })
}

export function userBindDoctor (form) {
  return http.post('/doctor_bind', form)
}
